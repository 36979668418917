$colour-plain: #fff;
$colour-primary: #00285f;
$colour-secondary: #d4002a;
$colour-accent: #f1f1f2;
$colour-accent-secondary: #ccc;
$colour-accent-dark: #282828;
$colour-success: #23a455;
$colour-success-light: desaturate(lighten($colour-success, 60%), 60%);
$colour-error: #d4002a;
$colour-plain-transparent: transparentize($colour-plain, 0.6);
$colour-primary-dark: darken($colour-primary, 10%);
$colour-primary-light: lighten($colour-primary, 4%);
$colour-accent-transparent: transparentize($colour-accent, 0.4);
$colour-accent-secondary-dark: darken($colour-accent-secondary, 10%);
$colour-accent-dark-transparent-semi: transparentize($colour-accent-dark, 0.2);
$colour-accent-dark-transparent: transparentize($colour-accent-dark, 0.6);
$colour-accent-dark-transparent-almost: transparentize($colour-accent-dark, 0.91);
$colour-accent-dark-transparent-full: transparentize($colour-accent-dark, 1);
$heading-one-transform: none;
$heading-transform: none;
$cta-primary-capitalisation: none;
$cta-tertiary-capitalisation: none;
$border-radius: 5px;
$cta-border-radius: 3px;
$typefaces: 'brand';

@import 'shared/mixins/font-face';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;600;800&display=swap&subset=arabic');
@include font-face('icons', 'avis-icons', 'avis-icons');
@import 'brand';
