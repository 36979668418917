.typeahead {

	&__list {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;
	}

	&__display {

		&:hover {
			background-color: $colour-primary;
			color: $colour-plain;
		}
	}
}
