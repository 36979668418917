.supplementary-navigation {

	&--minor {

		.supplementary-navigation__link {
			color: $colour-accent-dark;
		}
	}

	&__item {

		&--has-flyout {

			&:after {
				background: $colour-accent-dark-transparent-semi;
			}
		}

		&--active {

			.supplementary-navigation__link {
				background: $colour-primary;
				color: $colour-plain;
			}
		}
	}

	&__link {
		background: $colour-plain;
		color: $colour-primary;
		text-transform: $heading-transform;

		&:hover {
			background: $colour-primary;
			border-bottom-width: 0;
			color: $colour-plain;
		}

		&__inner {
			font-family: $font-family-base;
			font-weight: $font-weight-base;
			text-transform: $heading-transform;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}
		}
	}

	&__flyout {
		background: $colour-plain;

		h3 {
			border-color: $colour-accent-secondary;
		}

		.search {

			.icon {
				color: $colour-accent-secondary;
			}
		}
	}
}
