.promo {
	border-radius: $border-radius;

	&__details {
		background: $colour-accent;
		color: $colour-accent-dark;

		.l-row--variant-a & {
			background: $colour-plain;
		}
	}

	&__heading {
		@extend %h2;
	}

	&__cta {
		@extend .cta;
	}
}
