.date-picker {
	background: $colour-plain;
	border-color: $colour-accent-secondary;
	border-radius: $border-radius;

	@include breakpoint($breakpoint-small) {
		background: $colour-accent;
		border-radius: 0;

		&:before {
			border: solid transparent;
			border-top-color: $colour-plain;
			border-width: 9px;
		}
	}

	.pika-single {
		background: transparent;
	}

	.pika-title {
		text-transform: $heading-transform;
	}

	.pika-prev {

		&:before {
			color: $colour-primary;
		}
	}

	.pika-next {

		&:before {
			color: $colour-primary;
		}
	}

	.pika-label {
		background: transparent;
		color: $colour-accent-dark;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		@include breakpoint($breakpoint-small) {
			background: $colour-accent;
		}
	}

	.pika-table {

		tr:nth-child(even) td,
		tr:nth-child(even) th {
			background: transparent;
		}

		th,
		th[scope=col] {
			color: $colour-accent-dark;
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}
	}

	.pika-button {
		color: $colour-accent-dark;
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}
	}

	.is-selected .pika-button {
		background: $colour-primary;
		border-radius: $border-radius;
		color: $colour-plain;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}
}

.standard-form__datepicker {
	background: $colour-plain;
	border-color: $colour-accent-secondary;
	border-radius: $border-radius;

	.pika-prev {

		&:before {
			color: $colour-primary;
		}
	}

	.pika-next {

		&:before {
			color: $colour-primary;
		}
	}

	.pika-label {
		background: $colour-plain;
		color: $colour-accent-dark;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	.pika-table {

		tr:nth-child(even) td,
		tr:nth-child(even) th {
			background: transparent;
		}

		th,
		th[scope=col] {
			color: $colour-accent-dark;
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}
	}

	.pika-button {
		color: $colour-accent-dark;
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}
	}

	.is-selected .pika-button {
		background: $colour-primary;
		border-radius: $border-radius;
		color: $colour-plain;
	}
}
