.search-result {
	border-color: $colour-accent-secondary;

	.l-row--variant-b &,
	.l-row--variant-c &,
	.l-row--variant-d & {
		border-bottom-color: $colour-plain;
	}

	&__abstract {
		margin-bottom: 0;

		mark {
			font-family: $font-family-bold;
			font-weight: $font-weight-bold;

			@include rtl() {
				font-family: $font-family-bold-arabic;
				font-weight: $font-weight-bold-arabic;
			}
		}
	}
}
