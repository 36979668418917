.reference-list {

	&__heading {
		border-top-color: $colour-accent-secondary;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		.l-row--variant-b & {
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-plain;
		}

		.icon {
			color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}
	}

	&__value {
		border-color: $colour-accent-secondary;

		.l-row--variant-b & {
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-plain;
		}
	}

	&__note {
		color: $colour-accent-dark;

		.l-row--variant-b & {
			color: $colour-plain;
		}
	}
}
