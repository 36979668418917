.checklist {

	&--info {

		li {

			&:before {
				font-size: $font-family-bold;
				font-weight: $font-weight-bold;

				@include rtl() {
					font-family: $font-family-bold-arabic;
					font-weight: $font-weight-bold-arabic;
				}
			}
		}
	}
}
