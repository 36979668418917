.language-country-selector {

	&__toggle {
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}

		.icon {
			color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}
	}

	&__flyout {

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-accent-dark;
		}

		&:before {
			background: $colour-plain;
			box-shadow: 0 2px 0 $colour-accent-dark-transparent-almost;
		}
	}

	&__col {

		&:after {
			border-color: $colour-accent-secondary;
		}

		&--last {

			@include breakpoint($breakpoint-large) {
				border-color: $colour-accent-secondary;
			}
		}
	}

	&__heading {
		flex-shrink: 0;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;
		text-transform: $heading-transform;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		.icon {
			color: $colour-primary;
		}
	}
}
