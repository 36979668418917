.search {

	&__heading {
		@extend %h3;
	}

	&__inner {
		background: $colour-plain;

		.search__input {
			background: transparent;
		}
	}

	&__input {
		@extend .standard-form__input;
	}
}
