.cta {
	background: $colour-primary;
	border-radius: $cta-border-radius;
	color: $colour-plain;
	font-family: $font-family-brand;
	font-weight: $font-weight-brand;
	text-transform: $cta-primary-capitalisation;

	@include rtl() {
		font-family: $font-family-brand-arabic;
		font-weight: $font-weight-brand-arabic;
	}

	&:hover {
		background-color: $colour-accent-dark;
	}

	&[disabled] {
		background: $colour-accent-secondary;

		&:hover {
			background: $colour-accent-secondary;
		}
	}

	.l-row--variant-b & {
		background-color: $colour-accent-dark;

		&:hover {
			background-color: $colour-accent-secondary;
			color: $colour-secondary;
		}
	}

	.l-row--variant-c &,
	.l-row--variant-d & {

		&:hover {
			background-color: $colour-accent-secondary;
			color: $colour-secondary;
		}
	}

	.modal--alert & {

		&:hover {
			background-color: $colour-plain;
			color: $colour-secondary;
		}
	}

	&--secondary {
		background: transparent;
		border-color: $colour-secondary;
		color: $colour-secondary;

		&:hover {
			background: $colour-accent-dark;
			border-color: $colour-accent-dark;
			color: $colour-plain;
        }

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			background: transparent;
			border-color: $colour-plain;
			color: $colour-plain;

			&:hover {
				background: $colour-plain;
				border-color: $colour-plain;
				color: $colour-accent-dark;
			}
		}
	}

	&--tertiary {
		background: transparent;
		border-radius: 0;
		color: $colour-accent-dark;

		&:before {
			color: $colour-primary;
		}

		&:hover {
			background: transparent;
		}

		&[disabled] {
			background: transparent;
			color: $colour-accent-secondary;

			&:before {
				background: transparent;
				color: $colour-accent-secondary;
			}
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			background: transparent;
			color: $colour-plain;

			&:before {
				color: $colour-plain;
			}

			&:hover {
				background: transparent;
				color: $colour-plain;
    		}
		}
	}
}
