.social-promo {

	&__link {
		border: 0;

		&:hover {

			.icon {
				background-color: $colour-primary-light;
			}
		}

		.icon {
			background: $colour-primary-dark;
			color: $colour-plain;
		}
	}
}
