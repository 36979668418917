.modal {
	background: $colour-accent-dark-transparent-semi;

	&__inner {
		background: $colour-plain;
		border-radius: $border-radius;
	}

	&__close {
		background: $colour-primary;
		color: $colour-plain;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;
		text-transform: $heading-transform;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	&--alert {

		.modal__inner {
			background: $colour-accent-dark;
			color: $colour-plain;
		}
	}
}
