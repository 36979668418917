.link-list {
	&--collapsable {

		h2,
		h3,
		h4,
		h5,
		h6 {

			&:before {

				@include breakpoint($breakpoint-small) {
					color: $colour-primary;

					.l-row--variant-b &,
					.l-row--variant-c &,
					.l-row--variant-d & {
						color: $colour-plain;
					}
				}
			}
		}
	}

	&__link {

		&:before {
			color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}
	}

	&__sub-heading {
		font-family: $font-family-bold;
		font-weight: $font-weight-bold;

		@include rtl() {
			font-family: $font-family-bold-arabic;
			font-weight: $font-weight-bold-arabic;
		}
	}
}
