a {
	border-bottom-color: $colour-primary;
	color: $colour-accent-dark;

	.l-row--variant-b &,
	.l-row--variant-c & {
		border-bottom-color: $colour-plain;
		color: $colour-plain;
	}

	.l-row--variant-d & {
		color: $colour-plain;
	}
}
