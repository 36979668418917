.accordion {

	&__item {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: transparent;
		}

		&__toggle {

			&[disabled] + .accordion__item__heading {
				color: $colour-accent-secondary;

				&:before,
				&:hover {
					color: $colour-accent-secondary;
				}
			}

			&:checked + .accordion__item__heading,
			&:checked[disabled] + .accordion__item__heading:before,
			&:checked[disabled] + .accordion__item__heading:hover {
				color: $colour-primary;
			}

			&:checked + .accordion__item__heading--complete,
			&:checked[disabled] + .accordion__item__heading-complete:hover {
				color: $colour-success;
			}
		}

		&__heading {
			text-transform: $heading-one-transform;

			&:before {
				color: $colour-primary;
			}

			&:hover {
				color: $colour-primary;
			}

			&--complete {
				color: $colour-success;

				&:hover {
					color: $colour-success;
				}
			}

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-accent-dark;
			}
		}

		&__content {

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-accent-dark;
			}
		}
	}
}
