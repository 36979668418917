.vehicle {

	.l-col--12:not(.vehicle-matrix) > & {

		.vehicle__inner {
			border-color: $colour-accent-secondary;
			border-radius: $border-radius;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		.vehicle__overview,
		.vehicle__specs {
			border-radius: 0;

			@include breakpoint($breakpoint-medium) {
				border-radius: $border-radius;
			}
		}

		.vehicle__specs {

			.vehicle__accordion {

				.accordion__item__heading {
					font-family: $font-family-bold;
					font-weight: $font-weight-bold;

					@include rtl() {
						font-family: $font-family-bold-arabic;
						font-weight: $font-weight-bold-arabic;
					}

					@include breakpoint($breakpoint-large) {
						font-family: $font-family-base;
						font-weight: $font-weight-base;

						@include rtl() {
							font-family: $font-family-base-arabic;
							font-weight: $font-weight-base-arabic;
						}
					}
				}

				.accordion__item {

					&:after {
						border-color: $colour-accent-secondary;
					}
				}
			}
		}
	}

	.l-container--6-3-3 .l-col:first-child &,
	.l-container--3-6-3 .l-col:nth-child(2) &,
	.l-container--3-3-6 .l-col:last-child & {

		@include breakpoint($breakpoint-large) {
			.vehicle__inner {
				border-color: $colour-accent-secondary;
				border-radius: $border-radius;

				.l-row--variant-b & {
					border-color: $colour-primary-dark;
				}

				.l-row--variant-c &,
				.l-row--variant-d & {
					border-color: $colour-plain;
				}
			}

			.vehicle__overview,
			.vehicle__specs {
				border-radius: 0;

				@include breakpoint($breakpoint-medium) {
					border-radius: $border-radius;
				}
			}

			.vehicle__specs {

				.vehicle__accordion {

					.accordion__item__heading {
						font-family: $font-family-bold;
						font-weight: $font-weight-bold;

						@include rtl() {
							font-family: $font-family-bold-arabic;
							font-weight: $font-weight-bold-arabic;
						}

						@include breakpoint($breakpoint-large) {
							font-family: $font-family-base;
							font-weight: $font-weight-base;

							@include rtl() {
								font-family: $font-family-base-arabic;
								font-weight: $font-weight-base-arabic;
							}
						}
					}

					.accordion__item {

						&:after {
							border-color: $colour-accent-secondary;
						}
					}
				}
			}
		}
	}

	&--has-label {

		.vehicle__overview,
		.vehicle__specs {
			border-top-left-radius: 0;

			@include rtl() {
				border-top-left-radius: $border-radius;
				border-top-right-radius: 0;
			}
		}

		.l-col--12:not(.vehicle-matrix) > & {

			.vehicle__inner {
				border-radius: 0 $border-radius $border-radius;

				@include rtl() {
					border-radius: $border-radius 0 $border-radius $border-radius;
				}

				@include breakpoint($breakpoint-medium) {
					border-radius: 0;

					@include rtl() {
						border-radius: 0;
					}
				}
			}

			.vehicle__overview,
			.vehicle__specs {
				border-radius: 0;

				@include breakpoint($breakpoint-medium) {
					border-radius: 0 $border-radius $border-radius;

					@include rtl() {
						border-radius: $border-radius 0 $border-radius $border-radius;
					}
				}
			}
		}
	}

	&--recommended,
	&--trade-up {

		.vehicle__overview,
		.vehicle__specs {
			border-color: $colour-success;
			border-top-left-radius: 0;

			@include rtl() {
				border-top-left-radius: $border-radius;
				border-top-right-radius: 0;
			}

			.l-row--variant-a &,
			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-success;
			}
		}

		.l-col--12:not(.vehicle-matrix) > & {

			.vehicle__inner {
				border-color: $colour-success;
				border-radius: 0 $border-radius $border-radius;

				@include rtl() {
					border-radius: $border-radius 0 $border-radius $border-radius;
				}

				@include breakpoint($breakpoint-medium) {
					border-radius: 0;

					@include rtl() {
						border-radius: 0;
					}
				}
			}

			.vehicle__overview,
			.vehicle__specs {
				border-radius: 0;

				@include breakpoint($breakpoint-medium) {
					border-radius: 0 $border-radius $border-radius;

					@include rtl() {
						border-radius: $border-radius 0 $border-radius $border-radius;
					}
				}
			}
		}
	}

	&--trade-up {

		.vehicle__overview,
		.vehicle__specs {
			border-color: $colour-secondary;

			.l-row--variant-a &,
			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-secondary;
			}
		}

		.l-col--12:not(.vehicle-matrix) > & {

			.vehicle__inner {
				border-color: $colour-secondary;
			}
		}
	}

	&__overview {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;

		.l-row--variant-a & {
			background: $colour-accent;
		}

		.l-row--variant-b & {
			background: $colour-primary;
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c & {
			background: $colour-accent-dark;
			border-color: $colour-plain;
			color: $colour-plain;
		}

		.l-row--variant-d & {
			background: $colour-secondary;
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}

	&__label {
		background: $colour-success;
		border-radius: $border-radius $border-radius 0 0;
		color: $colour-plain;
		font-family: $font-family-bold;
		font-weight: 700;

		.vehicle--trade-up & {
			background: $colour-secondary;
		}

		.tooltip--variant {
			border-color: $colour-plain;
			color: $colour-plain;
		}

		&--reduced-rental {
			background: $colour-accent-secondary-dark;

			.l-row--variant-b & {
				background: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				background: $colour-plain;
				color: $colour-accent-dark;

				.tooltip--variant {
					border-color: $colour-accent-dark;
					color: $colour-accent-dark;
				}
			}

			.vehicle--trade-up & {
				background: $colour-accent-secondary-dark;

				.l-row--variant-b & {
					background: $colour-primary-dark;
				}

				.l-row--variant-c &,
				.l-row--variant-d & {
					background: $colour-plain;
					color: $colour-accent-dark;

					.tooltip--variant {
						border-color: $colour-accent-dark;
						color: $colour-accent-dark;
					}
				}
			}
		}
	}

	&__range {
		border-color: $colour-accent-secondary;
	}

	&__toggle {
		border-color: $colour-primary;
		color: $colour-accent-dark;
		font-family: $font-family-base;
		text-transform: $cta-tertiary-capitalisation;

		@include rtl() {
			font-family: $font-family-base-arabic;
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}

	&__feature {
		border-color: $colour-accent-secondary;
		font-family: $font-family-brand;


		@include rtl() {
			font-family: $font-family-brand-arabic;
		}

		.l-row--variant-b & {
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-plain;
		}

		@include rtl() {
			border-color: $colour-accent-secondary;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}
	}

	&__promo-message {
		background: $colour-accent;
		border-radius: $border-radius;
		color: $colour-primary;

		.l-row--variant-a & {
			background: $colour-plain;
		}

		.l-row--variant-b & {
			background: $colour-primary-dark;
			color: $colour-plain;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			background: $colour-accent-secondary;
			color: $colour-primary;
		}
	}

	&__prices-option {

		&:after {
			border-color: $colour-accent-secondary;
		}
	}

	&__prices-heading {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;
		text-transform: $heading-transform;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	&__prices-price {
		font-family: $font-family-bold;
		font-weight: 700;

		@include rtl() {
			font-family: $font-family-bold-arabic;
		}
	}

	&__prices-cta {
		@extend .cta;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-bottom-color: transparent;
		}

		.vehicle__prices-option:not(.vehicle__prices-option--primary) & {
			@extend .cta--secondary;
		}
	}

	&__prices-note {

		&--additional {
			border-color: $colour-success;
			border-radius: $cta-border-radius;
		}
	}

	&__specs {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;

		.l-row--variant-a & {
			background: $colour-accent;
		}

		.l-row--variant-b & {
			background: $colour-primary;
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c & {
			background: $colour-accent-dark;
			border-color: $colour-plain;
			color: $colour-plain;
		}

		.l-row--variant-d & {
			background: $colour-secondary;
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}

	&__accordion {

		.accordion__item {
			border-radius: 0;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		.accordion__item__heading {

			&:before {

				.l-row--variant-b &,
				.l-row--variant-c &,
				.l-row--variant-d & {
					border-color: $colour-plain;
					color: $colour-plain;
				}
			}

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}

		.accordion__item__content {

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {

			.accordion__item__toggle:checked + .accordion__item__heading,
			.accordion__item__toggle:checked[disabled] + .accordion__item__heading:hover {
				color: $colour-plain;

				&:before {
					color: $colour-plain;
				}
			}
		}
	}

	&--select,
	&--prestige,
	&--eco {

		.vehicle__category {

			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-accent-dark;
			}

			&:before {
				background: $colour-accent;
				border-radius: ($border-radius - 1px) ($border-radius - 1px) 0 0;

				.l-row--variant-a & {
					background-color: $colour-plain;
				}

				.l-row--variant-b & {
					background-color: $colour-primary-dark;
				}

				.l-row--variant-c &,
				.l-row--variant-d & {
					border-color: $colour-plain;
				}
			}
		}

		.l-col--12:not(.vehicle-matrix) > & {

			.vehicle__category:before {
				border-top-right-radius: 0;

				@include rtl() {
					border-top-left-radius: 0;
					border-top-right-radius: $border-radius - 1px;
				}

				@include breakpoint($breakpoint-medium) {
					border-top-right-radius: $border-radius - 1px;

					@include rtl() {
						border-top-left-radius: $border-radius - 1px;
					}
				}
			}

			.vehicle__overview {

				&:before {
					background: $colour-accent;
					border-top-right-radius: $border-radius - 1px;

					@include rtl() {
						border-top-left-radius: $border-radius - 1px;
						border-top-right-radius: 0;
					}

					.l-row--variant-a & {
						background-color: $colour-plain;
					}

					.l-row--variant-b & {
						background-color: $colour-primary-dark;
					}
				}
			}
		}

		.vehicle__toggle {

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-primary;
				color: $colour-accent-dark;
			}
		}

		.vehicle__note {
			font-family: $font-family-brand;


			@include rtl() {
				font-family: $font-family-brand-arabic;
			}

			strong {
				color: $colour-primary;
				font-family: $font-family-bold;
				font-weight: 700;

				@include rtl() {
					font-family: $font-family-bold-arabic;
				}

				.l-row--variant-b &,
				.l-row--variant-c &,
				.l-row--variant-d & {
					color: $colour-plain;
				}
			}
		}

		.vehicle__feature {

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-accent-secondary;
			}

			@include rtl() {

				.l-row--variant-b &,
				.l-row--variant-c &,
				.l-row--variant-d & {
					border-color: $colour-accent-secondary;
				}
			}
		}

		.accordion__item__heading:before,
		.accordion__item__toggle:checked + .accordion__item__heading,
		.accordion__item__toggle:checked + .accordion__item__heading:before,
		.accordion__item__toggle:checked[disabled] + .accordion__item__heading:hover,
		.accordion__item__heading:hover,
		.checklist li:before {
			color: $colour-accent-dark;

			.l-row--variant-a & {
				color: $colour-accent-dark;
			}

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}
	}

	&--eco {

		.vehicle__category {

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-accent-dark;
			}

			&:before {
				background: $colour-success-light;

				.l-row--variant-a &,
				.l-row--variant-b & {
					background: $colour-success-light;
				}
			}
		}

		.vehicle__toggle {
			border-color: $colour-primary;
			color: $colour-accent-dark;
		}

		.vehicle__note strong {
			color: $colour-accent-dark;
		}

		.l-col--12:not(.vehicle-matrix) > & {

			.vehicle__overview {

				&:before {
					background: $colour-success-light;

					.l-row--variant-a &,
					.l-row--variant-b & {
						background: $colour-success-light;
					}
				}
			}
		}
	}
}
