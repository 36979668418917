body {
	color: $colour-accent-dark;
	font-family: $font-family-base;
	font-weight: $font-weight-base;

	@include rtl() {
		font-family: $font-family-base-arabic;
		font-weight: $font-weight-base-arabic;
	}
}
