.option-list {

	input {

		&:checked + .option-list__control--radio:before {
			background: $colour-primary;
		}
	}

	&__control {

		&:before {
			color: $colour-primary;
		}

		&:after {
			background: $colour-plain;
			border-color: $colour-accent-secondary;
		}

		&--radio {

			&:after {
				border-radius: 50%;
			}
		}
	}
}
