.vehicle-results-widget {

	&__promo {
		background: $colour-accent;
		border-radius: $border-radius;

		.l-row--variant-a & {
			background: $colour-plain;
		}

		.l-row--variant-b & {
			background: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			background: $colour-primary;
		}
	}
}
