.primary-navigation {
	background: $colour-plain;

	@include breakpoint($breakpoint-small) {

		&:after {
			background: $colour-accent-dark-transparent-semi;
		}
	}

	&__toggle {
		color: $colour-primary;

		&--active {
			background: $colour-primary;
			color: $colour-plain;
		}
	}

	&__list {

		@include breakpoint($breakpoint-small) {
			background: $colour-plain;
		}

		&:before {
			background: $colour-plain;
		}
	}

	&__item {

		&--has-mega-menu {

			&:after {
				background: $colour-accent-dark-transparent-semi;
			}

			&.primary-navigation__item--active-mega-menu {

				@include breakpoint($breakpoint-small) {
					background: $colour-plain;
				}

				.primary-navigation__link {
					background: $colour-plain;
					color: $colour-primary;
				}

				.primary-navigation__mega-menu-toggle {
					color: $colour-primary;
				}
			}
		}

		&.mobile-only {
			display: none;

			@include breakpoint($breakpoint-small) {
				display: block;
			}
		}
	}

	&__link {
		color: $colour-primary;
		text-transform: $heading-transform;

		@include breakpoint($breakpoint-small) {
			color: $colour-accent-dark;
		}

		&:hover {
			background: $colour-primary;
			border-bottom-width: 0;
			color: $colour-plain;

			@include breakpoint($breakpoint-small) {
				background: transparent;
				color: $colour-primary;
			}
		}
	}

	&__mega-menu {
		background: $colour-plain;


		@include breakpoint($breakpoint-small) {
			background: $colour-plain;
		}

		&__heading {

			@include breakpoint($breakpoint-small) {
				border-color: $colour-primary;
				color: $colour-primary;
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;
				text-transform: $heading-transform;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}
			}
		}

		&__link {
			text-transform: $heading-transform;

			&:before {
				color: $colour-primary;
			}

			&--back {
				background: $colour-plain;
				color: $colour-accent-dark;
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}
			}
		}
	}
}
