.card {
	border-color: $colour-accent-secondary;
	border-radius: $border-radius;

	.l-row--variant-b & {
		border-color: $colour-primary-dark;
	}

	.l-row--variant-c &,
	.l-row--variant-d & {
		border-color: $colour-plain;
	}
}
