.option-promo {
	border-radius: $border-radius;

	.l-row--variant-b &,
	.l-row--variant-c &,
	.l-row--variant-d & {
		color: $colour-accent-dark;
	}

	&__header {
		background: $colour-accent;

		.l-row--variant-a & {
			background: $colour-plain;
		}
	}

	&__heading {
		@extend .h3;
	}

	&__panel {
		background: $colour-accent;
		border-color: $colour-accent-secondary;

		.l-row--variant-a & {
			background: $colour-plain;
		}

		a {

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-bottom-color: $colour-primary;
				color: $colour-accent-dark;
			}
		}
	}

	&--preferred {

		&:before {
			content: url('../img/avis-preferred-logo.svg');
		}
	}
}
