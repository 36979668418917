.extra {

	&__label {
		background: $colour-secondary;
		border-radius: $border-radius $border-radius 0 0;
		color: $colour-plain;
		font-family: $font-family-bold;
		font-weight: $font-weight-bold;

		@include rtl() {
			font-family: $font-family-bold-arabic;
			font-weight: $font-weight-bold-arabic;
		}

		.l-row--variant-b & {
			background: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			background: $colour-plain;
			color: $colour-accent-dark;
		}
	}

	&__price {
		font-family: $font-family-bold;
		font-weight: $font-weight-bold;

		@include rtl() {
			font-family: $font-family-bold-arabic;
			font-weight: $font-weight-bold-arabic;
		}

		&--minor {
			color: $colour-secondary;
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}
	}

	&__quantity-select {

		input {
			background: transparent;
			border-color: $colour-primary;
			border-radius: $border-radius;
			color: $colour-accent-dark;
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
				color: $colour-plain;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
				color: $colour-plain;
			}
		}

		.ui-button {
			color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}

			&--disabled {
				opacity: 0.5;
			}
		}
	}

	&__added {

		.icon {
			color: $colour-success;
		}
	}

	&--free {
		border-color: $colour-secondary;
		border-top-left-radius: 0;
	}

	&--slim {
		border-color: $colour-accent-secondary;

		&.extra--slim {
			border-color: $colour-accent-secondary;
		}
	}

	&--active {
		border-color: $colour-primary;

		.l-row--variant-b & {
			border-color: $colour-plain;
		}

		&.extra--free {
			border-color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		.extra__label {
			background-color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				background-color: $colour-plain;
				color: $colour-accent-dark;
			}
		}

		.extra__actions--single {

			.cta {
				@extend .cta--secondary;
			}
		}

		.extra__actions {

			+ .cta[data-trigger=remove-all] {
				@extend .cta--secondary;
			}
		}
	}

	&--included {
		background: $colour-accent;

		.l-row--variant-a & {
			background: $colour-plain;
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			background: transparent;
		}
	}

	[data-trigger=reveal] + p {
		margin-bottom: 0;
		margin-top: $spacing-unit-base;
	}
}
