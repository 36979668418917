.delivery-and-collection {

	&__breakdown {
		border-color: $colour-accent-secondary;

		&__heading {
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}
		}

		&__price {
			font-family: $font-family-bold;
			font-weight: $font-weight-bold;

			@include rtl() {
				font-family: $font-family-bold-arabic;
				font-weight: $font-weight-bold-arabic;
			}
		}

		&__label {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}
	}

	&__remove-cta {
		border-color: $colour-accent-secondary;
		color: $colour-primary;
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}
	}
}
