.language-selector {

	&__link {

		&--active {
			border-color: $colour-primary;
			font-family: $font-family-brand;


			@include rtl() {
				font-family: $font-family-brand-arabic;
			}

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		&:hover {
			border-bottom-width: 0;
			border-top-color: $colour-primary;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}
	}

	&__select {

		.icon {
			color: $colour-primary;
		}

		&:before {
			color: $colour-primary;
		}

		select {
			color: $colour-accent-dark;
			font-family: $font-family-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
			}

			&::-ms-value {
				background: transparent;
				color: $colour-accent-dark;
			}
		}
	}
}
