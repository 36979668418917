.itinerary {
	border-color: $colour-accent-secondary;

	.l-row--variant-b & {
		border-color: $colour-primary-dark;
	}

	.l-row--variant-c &,
	.l-row--variant-d & {
		border-color: $colour-plain;
	}

	h2 ~ &,
	h2 ~ .reveal & {

		.itinerary__details-heading {
			@extend %h3;
		}
	}

	&__details-heading {
		@extend %h2;
	}
}
