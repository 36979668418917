table {

	th {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	tr:nth-child(even) {

		td,
		th {
			background: $colour-accent;

			.l-row--variant-a & {
				background: $colour-plain;
			}

			.l-row--variant-b & {
				background: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				background: $colour-primary;
			}
		}
	}

	.table--strip-inverse & {

		td:nth-child(even),
		th:nth-child(even) {
			background: $colour-accent;

			.l-row--variant-a & {
				background: $colour-plain;
			}

			.l-row--variant-b & {
				background: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				background: $colour-primary;
			}
		}

		tr:nth-child(even) {

			td:nth-child(odd),
			th:nth-child(odd) {
				background: transparent;

				.l-row--variant-a &,
				.l-row--variant-b &,
				.l-row--variant-c &,
				.l-row--variant-d & {
					background: transparent;
				}
			}
		}
	}
}
