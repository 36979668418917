.title {

	&--with-image {

		.title__heading,
		.title__sub-heading {
			color: $colour-plain;
		}
	}

	&__text {

		&--light {
			background: $colour-plain-transparent;
			color: $colour-primary;

			.title__heading,
			.title__sub-heading {
				color: $colour-primary;
			}
		}

		&--dark {
			background: $colour-accent-dark-transparent;
			color: $colour-plain;

			.title__heading,
			.title__sub-heading {
				color: $colour-plain;
			}
		}
	}
}
