ul {

	li {

		&:before {
			color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}
	}
}

ol {

	li {

		&:before {
			color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}
	}
}
