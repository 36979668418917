.text {

	&--highlight {
		background: $colour-accent;

		.l-row--variant-a &,
		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			background: transparent;
		}
	}

	&--with-icon {

		.text__heading-icon {
			color: $colour-primary;

			.l-row--variant-b & {
				color: $colour-plain;
			}
		}
	}
}
