.message {

	&__heading {
		color: $colour-success;

		&:before {
			border-color: $colour-success;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		.modal--alert &,
		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}
	}

	&__description {
		@extend %h3;

		&--minor {
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}
		}
	}

	&--error {

		.message__heading {
			color: $colour-error;

			.modal--alert &,
			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}

			&:before {
				border-color: $colour-error;

				.l-row--variant-b &,
				.l-row--variant-c &,
				.l-row--variant-d & {
					border-color: $colour-plain;
				}
			}

			&:after {
				border-color: $colour-error;

				.l-row--variant-b &,
				.l-row--variant-c &,
				.l-row--variant-d & {
					border-color: $colour-plain;
				}
			}
		}
	}
}
