.step-tracker {

	&__overview {
		background: $colour-accent;
		border-color: $colour-accent-secondary;

		&:before {
			background: $colour-accent;
			border-color: $colour-plain;
		}

		&--added,
		&--removed {
			background: $colour-success;
			color: $colour-plain;
		}
	}

	&__overview-heading {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	&__overview-price {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	&__flash-message {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	&__step-toggle {
		color: $colour-primary;

		&__inner {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}
	}

	&__progress-bar {
		background: $colour-accent;
		border-color: $colour-plain;

		&:before {
			background: $colour-success;
			border-color: $colour-plain;
		}

		div {
			background: $colour-success;
		}

		&--four-quarters,
		&--three-thirds {

			&:after {
				background: $colour-success;
				border-color: $colour-plain;
			}
		}
	}

	&__step-wrapper {
		background: $colour-accent;
		border-color: $colour-accent-secondary;
	}

	&__step {
		border-color: $colour-accent-secondary;

		@include rtl() {
			border-color: $colour-accent-secondary;
		}

		@include breakpoint($breakpoint-large) {

			&:first-child,
			&:nth-child(2) {
				border-color: $colour-accent-secondary;

				&:before {
					border-color: $colour-accent-secondary;
				}
			}
		}

		@include breakpoint($breakpoint-small) {

			&:before {
				border-color: $colour-accent-secondary;
			}
		}

		&--complete {

			.step-tracker__step-heading:before {
				color: $colour-success;

				@include rtl() {
					font-family: 'icons';
				}
			}
		}
	}

	&__step-heading {
		color: $colour-primary;
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}

		&:before {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}
	}

	&__step-cta {
		border-color: $colour-primary;
		color: $colour-accent-dark;
		font-family: $font-family-base;
		font-weight: $font-weight-base;
		text-transform: $cta-tertiary-capitalisation;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}
	}

	&__step-prompt {

		&--inactive {
			color: $colour-accent-secondary-dark;
		}
	}

	&__step-note {
		border-color: $colour-accent-secondary;
	}

	&__itinerary {

		&:nth-child(2n+1) {

			&:before {
				color: $colour-primary;
			}
		}
	}

	&__dates {

		.icon {
			color: $colour-primary;
		}
	}

	&__close-toggle {
		background: $colour-accent;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		.icon {
			color: $colour-primary;
		}

		@include breakpoint($breakpoint-small) {
			display: block;
		}
	}

	&__promo-message {
		background: $colour-plain;
		border-radius: $border-radius;
		color: $colour-primary;
	}

	&__footer {
		background: $colour-accent;
	}

	&__totals {

		&__heading {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}

			&--total {
				border-color: $colour-accent-secondary;
			}
		}

		&__price {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}

			&--total {
				border-color: $colour-accent-secondary;
				font-family: $font-family-bold;
				font-weight: $font-weight-bold;

				@include rtl() {
					font-family: $font-family-bold-arabic;
					font-weight: $font-weight-bold-arabic;
				}
			}
		}

		&__note {
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}
		}
	}

	&__vehicle-preview {
		background: $colour-accent;
	}

	&__booking-widget {
		background: $colour-accent;
		border-color: $colour-accent-secondary;

		@include breakpoint($breakpoint-large) {
			background: $colour-accent-dark-transparent-semi;
		}

		&__inner {
			background: $colour-plain;
			border-radius: $border-radius;
		}

		&__close {
			background: $colour-primary;
			color: $colour-plain;
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;
			text-transform: $heading-transform;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}

		.booking-widget__date-picker-container {

			.standard-form__input {

				@include breakpoint($breakpoint-large) {
					color: $colour-accent-dark;
				}
			}
		}
	}

	&__confirm-details {
		background: $colour-accent;
		border-color: $colour-accent-secondary;

		&:after {
			background: $colour-accent-dark-transparent-semi;
		}

		&__inner {
			background: $colour-plain;
			border-radius: $border-radius;
		}
	}

	&--summary {

		.step-tracker__overview-heading {
			@extend .h2;
		}

		.step-tracker__line-item-price {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}

		.step-tracker__step-wrapper {

			@include breakpoint($breakpoint-small) {
				border-color: $colour-accent-secondary;
			}
		}
	}
}
