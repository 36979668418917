.l-row {

	&--variant-a {
		background: $colour-accent;
	}

	&--variant-b {
		background: $colour-primary;
		color: $colour-plain;
	}

	&--variant-c {
		background: $colour-accent-dark;
		color: $colour-plain;
	}

	&--variant-d {
		background: $colour-secondary;
		color: $colour-plain;
	}

	&--with-horizontal-separator {
		border-bottom-color: $colour-accent-secondary;
	}
}

.l-col {

	&:after {
		border-color: $colour-accent-secondary;
	}

	.l-row--variant-b & {
		color: $colour-plain;

		&:after {
			border-color: $colour-primary-dark;
		}
	}

	.l-row--variant-c,
	.l-row--variant-d & {
		color: $colour-plain;

		&:after {
			border-color: $colour-plain;
		}
	}
}
