.feature {

	&__icon {
		color: $colour-primary;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}
	}

	&__heading {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}
}
