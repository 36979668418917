.tooltip {
	background: $colour-accent-dark;
	border-radius: $border-radius;
	color: $colour-plain;

	&--variant {
		background: transparent;
		border-color: $colour-accent-dark;
		color: $colour-accent-dark;
	}

	.l-row--variant-b & {
		background: $colour-primary-dark;

		&--variant {
			background: transparent;
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}

	.l-row--variant-c &,
	.l-row--variant-d & {
		background: $colour-plain;
		color: $colour-accent-dark;

		&--variant {
			background: transparent;
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}
}

.tippy-tooltip {

	&--custom-theme {
		background: $colour-accent-dark;
		border-radius: $border-radius;
		color: $colour-plain;

		.tippy-arrow {

			.tippy-popper[x-placement^=top] & {
				border: solid $colour-accent-dark-transparent-full;
				// scss-lint:disable PropertySortOrder
				// Need properties in this order to achieve triangle
				border-top-color: $colour-accent-dark;
				// scss-lint:enable PropertySortOrder
				border-width: 10px;
			}

			.tippy-popper[x-placement^=bottom] & {
				border: solid $colour-accent-dark-transparent-full;
				// scss-lint:disable PropertySortOrder
				// Need properties in this order to achieve triangle
				border-bottom-color: $colour-accent-dark;
				// scss-lint:enable PropertySortOrder
				border-width: 10px;
			}

			.tippy-popper[x-placement^=left] & {
				border: solid $colour-accent-dark-transparent-full;
				// scss-lint:disable PropertySortOrder
				// Need properties in this order to achieve triangle
				border-left-color: $colour-accent-dark;
				// scss-lint:enable PropertySortOrder
			}

			.tippy-popper[x-placement^=right] & {
				border: solid $colour-accent-dark-transparent-full;
				// scss-lint:disable PropertySortOrder
				// Need properties in this order to achieve triangle
				border-right-color: $colour-accent-dark;
				// scss-lint:enable PropertySortOrder
			}
		}
	}
}
