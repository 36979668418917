%h1 {
	color: $colour-primary;
	font-family: $font-family-bold;
	font-weight: $font-weight-bold;
	text-transform: $heading-one-transform;

	@include rtl() {
		font-family: $font-family-bold-arabic;
		font-weight: $font-weight-bold-arabic;
	}

	@include breakpoint($breakpoint-small) {
		text-transform: none;
	}

	.l-row--variant-b &,
	.l-row--variant-c &,
	.l-row--variant-d & {
		color: $colour-plain;
	}
}

h1,
.h1 {
	@extend %h1;
}

%h2 {
	font-family: $font-family-bold;
	font-weight: $font-weight-bold;
	text-transform: $heading-transform;

	@include rtl() {
		font-family: $font-family-bold-arabic;
		font-weight: $font-weight-bold-arabic;
	}
}

h2,
.h2 {
	@extend %h2;
}

%h3 {
	font-family: $font-family-base;
	font-weight: $font-family-base;
	text-transform: $heading-transform;

	@include rtl() {
		font-family: $font-family-base-arabic;
		font-weight: $font-weight-base-arabic;
	}
}

h3,
.h3 {
	@extend %h3;
}

%h4 {
	font-family: $font-family-brand;
	font-weight: $font-weight-brand;
	text-transform: $heading-transform;

	@include rtl() {
		font-family: $font-family-brand-arabic;
		font-weight: $font-weight-brand-arabic;
	}
}

h4,
.h4 {
	@extend %h4;
}
