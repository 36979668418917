.highlight {
	background: $colour-accent;

	.l-row--variant-a & {
		background: $colour-plain;
	}

	.l-row--variant-b & {
		background: $colour-primary-dark;
	}

	.l-row--variant-c & {
		background: $colour-accent-dark;
	}

	.l-row--variant-d & {
		background: $colour-secondary;
	}
}
