.upsell {
	background: $colour-accent;

	.l-row--variant-a & {
		background: $colour-plain;
	}

	.l-row--variant-b &,
	.l-row--variant-c &,
	.l-row--variant-d & {
		color: $colour-accent-dark;
	}

	&:before {
		background: $colour-success;
	}

	&__feature {
		border-color: $colour-accent-secondary;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		@include rtl() {
			border-color: $colour-accent-secondary;
		}
	}

	&__caption {
		font-family: $font-family-bold;
		font-weight: $font-weight-bold;

		@include rtl() {
			font-family: $font-family-bold-arabic;
			font-weight: $font-weight-bold-arabic;
		}

		.upsell--active & {
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}
		}

		strong {
			color: $colour-success;
			font-family: $font-family-bold;
			font-weight: $font-weight-bold;

			@include rtl() {
				font-family: $font-family-bold-arabic;
				font-weight: $font-weight-bold-arabic;
			}
		}
	}
}
