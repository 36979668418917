@mixin icon() {
	font-family: 'icons';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
}

.icon {
	@include icon();

	&--ac:before {
		content: '\e00d';
	}

	&--airplane-depart:before {
		content: '\e093';
	}

	&--alert:before {
		content: '\e094';
	}

	&--arrow-back:before {
		content: '\4c';

		@include rtl() {
			content: '\4d';
		}
	}

	&--arrow-forward:before {
		content: '\4d';

		@include rtl() {
			content: '\4c';
		}
	}

	&--bell:before {
		content: '\e90b';
	}

	&--briefcase:before {
		content: '\e00e';
	}

	&--calendar:before {
		content: '\e909';
	}

	&--car:before {
		content: '\e908';
	}

	&--car-circled:before {
		content: '\e099';
	}

	&--cards:before {
		content: '\e084';
	}

	&--chevron-back:before {
		content: '\e00f';

		@include rtl() {
			content: '\e004';
		}
	}

	&--chevron-down:before {
		content: '\e015';
	}

	&--chevron-forward:before {
		content: '\e004';

		@include rtl() {
			content: '\e00f';
		}
	}

	&--chevron-up:before {
		content: '\e014';
	}

	&--clock:before {
		content: '\e90c';
	}

	&--cogs:before {
		content: '\e008';
	}

	&--cross:before {
		content: '\e002';
	}

	&--envelope:before {
		content: '\e910';
	}

	&--facebook:before {
		content: '\e088';
	}

	&--globe:before {
		content: '\e905';
	}

	&--globe-alt:before {
		content: '\e90a';
	}

	&--google-plus:before {
		content: '\e087';
	}

	&--hamburger:before {
		content: '\e016';
	}

	&--info:before {
		content: '\e902';
	}

	&--instagram:before {
		content: '\e90e';
	}

	&--key:before {
		content: '\e90f';
	}

	&--language:before {
		content: '\e906';
	}

	&--leaf:before {
		content: '\e01f';
	}

	&--lightning-bolt:before {
		content: '\e01e';
	}

	&--location-pin:before {
		content: '\e948';
	}

	&--lock:before {
		content: '\e01c';
	}

	&--lock-open:before {
		content: '\e01d';
	}

	&--magnifying-glass:before {
		content: '\e017';
	}

	&--minus:before {
		content: '\e900';
	}

	&--office:before {
		content: '\e091';
	}

	&--petrol:before {
		content: '\e009';
	}

	&--petrol-circled:before {
		content: '\e098';
	}

	&--phone:before {
		content: '\e005';
	}

	&--plus:before {
		content: '\e901';
	}

	&--printer:before {
		content: '\e01b';
	}

	&--rotate:before {
		content: '\e904';
	}

	&--question-mark:before {
		content: '\e903';
	}

	&--share:before {
		content: '\e003';
	}

	&--snowflake:before {
		content: '\e800';
	}

	&--speedometer:before {
		content: '\e907';
	}

	&--target:before {
		content: '\e018';
	}

	&--tick:before {
		content: '\e001';
	}

	&--tick-circled:before {
		content: '\e020';
	}

	&--timer:before {
		content: '\e911';
	}

	&--train:before {
		content: '\e092';
	}

	&--transmission:before {
		content: '\e00a';
	}

	&--twitter:before {
		content: '\e089';
	}

	&--van:before {
		content: '\e802';
	}

	&--vehicle-door:before {
		content: '\e00b';
	}

	&--wheel:before {
		content: '\e007';
	}

	&--user:before {
		content: '\e00c';
	}

	&--user-card:before {
		content: '\e085';
	}

	&--user-circled:before {
		content: '\e097';
	}

	&--youtube:before {
		content: '\e90d';
	}

	+ .sr-only {
		left: -99999em;
		position: absolute;
		top: -99999em;

		@include rtl() {
			left: auto;
			right: -99999em;
		}
	}
}
