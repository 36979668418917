.sign-in-bar {

	&__welcome {

		a {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}
	}

	&__detail {
		border-right-color: $colour-accent-secondary;

		@include rtl() {
			border-left-color: $colour-accent-secondary;
		}

		&__content {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}

		~ .sign-in-bar__detail {

			@include breakpoint($breakpoint-small) {
				border-top-color: $colour-accent-secondary;
			}

			.supplementary-navigation__flyout & {
				border-top-color: $colour-accent-secondary;
			}
		}
	}

	&__progress-bar {

		&__bar {
			background: $colour-accent-secondary;
			color: $colour-success;

			&::-webkit-progress-bar {
				background: $colour-accent-secondary;
			}

			&::-webkit-progress-value,
			&::-moz-progress-bar {
				background: $colour-success;
			}

			&::-ms-fill {
				border-color: $colour-success;
			}
		}
	}

	&__action {

		@include breakpoint($breakpoint-small) {
			border-top-color: $colour-accent-secondary;
		}

		.supplementary-navigation__flyout & {
			border-top-color: $colour-accent-secondary;
		}
	}
}
