.time-picker {
	background: $colour-plain;
	border-color: $colour-accent-secondary;
	border-radius: $border-radius;

	.ui-timepicker-list {

		li {
			border-color: $colour-accent-secondary;
		}
	}

	li.ui-timepicker-selected {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	.ui-timepicker-list:hover .ui-timepicker-selected,
	li.ui-timepicker-selected,
	.ui-timepicker-list li:hover,
	.ui-timepicker-list .ui-timepicker-selected:hover {
		background: $colour-plain;
		color: $colour-accent-dark;
	}

	.ui-timepicker-list li.ui-timepicker-disabled,
	.ui-timepicker-list li.ui-timepicker-disabled:hover,
	.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
		color: $colour-accent-secondary;
	}

	.ui-timepicker-list li.ui-timepicker-disabled:hover,
	.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
		background: transparent;
	}
}
