.pagination {

	&__previous-link,
	&__next-link {
		color: $colour-accent-dark;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;
		text-transform: $cta-tertiary-capitalisation;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}

		&:before {
			color: $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}

		&:hover {
			border-bottom: 0;
			text-decoration: underline;
		}
	}
}
