.alert {
	background: $colour-accent;
	border-radius: $border-radius;
	color: $colour-primary;

	.l-row--variant-a & {
		background: $colour-plain;
	}

	.l-row--variant-b & {
		background: $colour-primary-dark;
		color: $colour-plain;
	}

	.l-row--variant-c &,
	.l-row--variant-d & {
		background: $colour-primary;
		color: $colour-plain;
	}

	&--error {
		background: transparent;
		border-color: $colour-error;

		.icon {
			color: $colour-error;
		}
	}

	&--success {
		background: transparent;
		border-color: $colour-success;

		.icon {
			color: $colour-success;
		}
	}

	&--info {
		background: transparent;
		border-color: $colour-secondary;

		.icon {
			color: $colour-secondary;
		}
	}
}
