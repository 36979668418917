.header {

	&__row {
		background: $colour-plain;

		&--main {

			&:before {
				background: $colour-plain;
			}
		}

		&--secondary {
			background: $colour-accent;
		}
	}
}
