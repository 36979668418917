.map {

	&__info {
		background: $colour-plain;
		border-radius: $border-radius;

		&:before {
			border: solid transparent;
			border-right-color: $colour-plain;
			border-width: 30px;
		}
	}

	&__info-close {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		.icon {
			background: $colour-primary;
			color: $colour-plain;
		}
	}

	&__info-heading {

		@include breakpoint($breakpoint-small) {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}
	}

	&__info-col {

		+ .map__info-col {
			border-left-color: $colour-accent-secondary;

			@include rtl() {
				border-right-color: $colour-accent-secondary;
			}
		}
	}

	&__info-sub-heading {
		text-transform: $heading-transform;

		.icon {
			color: $colour-primary;
		}
	}
}
