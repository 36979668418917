.booking-actions {

	&__price {
		color: $colour-primary;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		span {
			font-family: $font-family-bold;
			font-weight: $font-weight-bold;

			@include rtl() {
				font-family: $font-family-bold-arabic;
				font-weight: $font-weight-bold-arabic;
			}
		}
	}
}
