.booking-widget {

	&:before {
		background: linear-gradient(45deg, $colour-accent-dark-transparent, $colour-accent-dark-transparent-full);
	}

	&__promo {
		color: $colour-plain;

		a {
			border-bottom-color: $colour-plain;
			color: $colour-plain;
		}

		.cta {
			background: $colour-plain;
			color: $colour-primary;

			&:hover {
				background: $colour-accent-dark;
				color: $colour-plain;
			}
		}
	}

	&__heading {
		color: $colour-plain;
		font-family: $font-family-bold;
		font-weight: $font-weight-bold;
		text-transform: none;

		@include rtl() {
			font-family: $font-family-bold-arabic;
			font-weight: $font-weight-bold-arabic;
		}
	}

	&__terms {
		border-bottom-color: $colour-plain;
		color: $colour-plain;
	}

	&__inner {
		background: $colour-plain;
		border-radius: 0 $border-radius $border-radius;

		@include rtl() {
			border-radius: $border-radius 0 $border-radius $border-radius;
		}

		@include breakpoint($breakpoint-small) {
			border-radius: 0;

			@include rtl() {
				border-radius: 0;
			}
		}

		&--no-tabs {
			border-radius: $border-radius;

			@include rtl() {
				border-radius: $border-radius;
			}

			.booking-widget--promo &,
			.booking-widget--slim & {
				border-radius: $border-radius;
			}

			@include breakpoint($breakpoint-small) {
				border-radius: 0;

				@include rtl() {
					border-radius: 0;
				}
			}
		}
	}

	&__tab {

		&:first-child {
			border-top-left-radius: $border-radius;

			@include rtl() {
				border-top-left-radius: 0;
				border-top-right-radius: $border-radius;
			}

			@include breakpoint($breakpoint-small) {
				border-radius: 0;

				@include rtl() {
					border-radius: 0;
				}
			}
		}

		&:last-child {
			border-top-right-radius: $border-radius;

			@include rtl() {
				border-top-left-radius: $border-radius;
				border-top-right-radius: 0;
			}

			@include breakpoint($breakpoint-small) {
				border-radius: 0;

				@include rtl() {
					border-radius: 0;
				}
			}
		}

		input {

			&:checked + .booking-widget__tab-control {
				background: $colour-plain;
				border-bottom-color: $colour-plain;

				&:before {
					background: $colour-primary;
				}
			}

			&:focus + .booking-widget__tab-control:after {
				border-color: $colour-accent-secondary;
			}
		}
	}

	&__tab-control {
		background: $colour-accent-transparent;
		text-transform: $cta-tertiary-capitalisation;

		@include breakpoint($breakpoint-small) {
			border-color: $colour-accent-secondary;
		}

		&:after {
			background: $colour-plain;
			border-color: $colour-accent-secondary;
		}
	}

	&__date-fields {

		&:before {
			color: $colour-primary;
		}

		@include breakpoint($breakpoint-medium) {

			.standard-form__input {

				.booking-widget & {
					font-family: $font-family-brand;
					font-weight: $font-weight-brand;

					@include rtl() {
						font-family: $font-family-brand-arabic;
						font-weight: $font-weight-brand-arabic;
					}
				}
			}
		}
	}

	&__date-picker-container {

		@include breakpoint($breakpoint-small) {

			&:after {
				background: $colour-plain;
				border-radius: $border-radius;
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}
			}
		}

		&:before {
			color: $colour-primary;
		}
	}

	&__time-picker-container {

		@include breakpoint($breakpoint-small) {
			background: $colour-plain;
			border-color: $colour-accent-secondary;
			border-radius: $border-radius;

			select {
				color: $colour-accent-dark;
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}

				&::-ms-value {
					color: $colour-accent-dark;
				}
			}
		}

		&:before {
			color: $colour-primary;
		}
	}

	.standard-form__label {
		text-transform: $cta-tertiary-capitalisation;
	}

	.standard-form__input {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		&--awd-applied {
			border-color: $colour-success;

			&:focus {
				border-color: $colour-success;
			}

			+ .icon.icon--tick-circled,
			~ .success {
				color: $colour-success;
			}
		}

		&::placeholder {
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}
		}
	}

	&__results-container {

		.standard-form__input {

			&[disabled] {
				background: $colour-plain;
				border-color: $colour-accent-secondary;
				color: $colour-accent-dark;
			}
		}
	}

	&__geo {
		border-radius: $border-radius;
		color: $colour-accent-dark;
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}

		&:before {
			color: $colour-primary;
		}
	}

	&__results {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;

		&__item {
			border-color: $colour-accent-secondary;
		}

		&__link {
			color: $colour-accent-dark;
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}

			&:hover {
				background-color: $colour-primary;
				color: $colour-plain;

				.booking-widget__result-distance {
					color: $colour-plain;
				}
			}

			&__prompt {
				border-color: $colour-primary;
				color: $colour-accent-dark;
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}
			}

			.icon {

				.booking-widget__results__item--highlight & {
					color: $colour-primary;
				}
			}

			.booking-widget__results__item--highlight & {
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}

				&:hover {
					color: $colour-plain;

					.booking-widget__results__link__prompt {
						border-color: $colour-plain;
						color: $colour-plain;
					}

					.icon {
						color: $colour-plain;
					}
				}
			}
		}

		&__distance {
			color: $colour-secondary;
		}
	}

	&__destination-results {
		background: $colour-plain;
		border-radius: $border-radius;

		&__close {
			color: $colour-primary;
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}

		&__tabs {

			.booking-widget--promo &,
			.booking-widget--slim & {
				border-color: $colour-accent-secondary;
			}

			@include breakpoint($breakpoint-medium) {
				border-color: $colour-accent-secondary;
			}

			&__link {
				background: $colour-accent;
				border-color: $colour-accent-secondary;
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}

				&:hover {
					color: $colour-primary;
				}

				&--active {
					background: $colour-plain;
					border-bottom-color: $colour-plain;
					color: $colour-primary;
				}
			}
		}

		&__list {

			&__item {
				border-color: $colour-accent-secondary;
			}

			&__link {
				color: $colour-accent-dark;
				font-family: $font-family-base;
				font-weight: $font-weight-base;

				@include rtl() {
					font-family: $font-family-base-arabic;
					font-weight: $font-weight-base-arabic;
				}

				&:hover {
					background: $colour-accent;
				}
			}

			&__distance {
				color: $colour-secondary;
			}
		}
	}

	&__opening-times {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: 0 $border-radius $border-radius 0;

		@include rtl() {
			border-radius: $border-radius 0 0 $border-radius;
		}

		@include breakpoint($breakpoint-small) {
			background: transparent;
			border-radius: 0;
		}

		.time-picker--has-opening-times & {
			background: transparent;
		}

		&:before {
			border-color: $colour-plain;
		}

		&__heading {
			text-transform: $heading-transform;

			&:before {
				color: $colour-primary;
			}

			@include breakpoint($breakpoint-small) {
				border-color: $colour-accent-secondary;
				color: $colour-primary;
			}
		}
	}

	&__station-details-toggle {
		border-color: $colour-primary;
		color: $colour-accent-dark;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;
		text-transform: $heading-transform;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		&:before {
			color: $colour-primary;
		}
	}

	&__station-details {
		background: $colour-plain;
		border-radius: $border-radius;

		@include breakpoint($breakpoint-small) {
			border-radius: 0;
		}

		&__close {
			color: $colour-primary;
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}

		&__header {
			text-transform: $heading-transform;
		}
	}

	&__wizard-controls {
		border-top-color: $colour-accent-secondary;
	}

	&--promo,
	&--slim {

		.l-col--6:first-child {

			@include breakpoint($breakpoint-small) {

				&:before {
					background: linear-gradient(45deg, $colour-accent-dark-transparent, $colour-accent-dark-transparent-full);

					@include rtl() {
						background: linear-gradient(-45deg, $colour-accent-dark-transparent, $colour-accent-dark-transparent-full);
					}
				}
			}
		}

		.booking-widget__inner {

			@include breakpoint($breakpoint-large) {
				border-radius: 0 0 $border-radius $border-radius;

				@include rtl() {
					border-radius: 0 0 $border-radius $border-radius;
				}
			}
		}

		.booking-widget__date-fields {

			.standard-form__input {
				font-family: $font-family-brand;
				font-weight: $font-weight-brand;

				@include rtl() {
					font-family: $font-family-brand-arabic;
					font-weight: $font-weight-brand-arabic;
				}
			}
		}

		.booking-widget__date-picker-container {

			@include breakpoint($breakpoint-large) {

				&:after {
					background: $colour-plain;
					border-radius: $border-radius;
					font-family: $font-family-brand;
					font-weight: $font-weight-brand;

					@include rtl() {
						font-family: $font-family-brand-arabic;
						font-weight: $font-weight-brand-arabic;
					}
				}

				&--open:after {
					border-color: $colour-accent;
				}
			}
		}
	}
}
