.coverage {

	&__overview {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;

		.l-row--variant-a & {
			background: $colour-accent;
		}

		.l-row--variant-b & {
			background: $colour-primary;
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c & {
			background: $colour-accent-dark;
			border-color: $colour-plain;
			color: $colour-plain;
		}

		.l-row--variant-d & {
			background: $colour-secondary;
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}

	&__header {
		background: $colour-accent;
		border-radius: $border-radius $border-radius 0 0;

		.l-row--variant-a & {
			background: $colour-plain;
		}

		.l-row--variant-b & {
			background: $colour-primary-dark;
		}

		.l-row--variant-c & {
			background: $colour-secondary;
		}

		.l-row--variant-d & {
			background: $colour-accent-dark;
		}
    }

	&__heading {
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}
	}

	&__toggle {
		border-color: $colour-primary;
		color: $colour-accent-dark;
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}

	&__description {

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-bottom-color: $colour-plain;
		}
	}

	&__breakdown {

		dt {
			border-color: $colour-accent-secondary;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		dd {
			border-color: $colour-accent-secondary;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}

			.icon {
				color: $colour-accent-secondary;

				&.icon--tick {
					color: $colour-success;
				}
			}
		}
	}

	&__option {

		.option-list__control {
			@extend .cta;

			&:before,
			&:after {
				display: none;
			}
		}
	}

	&__footer {
		background: $colour-accent;
		border-radius: 0 0 $border-radius $border-radius;

		.l-row--variant-a & {
			background: $colour-plain;
		}

		.l-row--variant-b & {
			background: $colour-primary-dark;
		}

		.l-row--variant-c & {
			background: $colour-secondary;
		}

		.l-row--variant-d & {
			background: $colour-accent-dark;
		}

		&--standard {

			.option-list__control,
			.option-list input:checked + .option-list__control--radio {
				// scss-lint:disable ImportantRule
				// Need emsure that this is always transparent
				background: transparent !important;
				// scss-lint:enable ImportantRule

				&:before {
					color: $colour-success;
				}
			}
		}
	}

	&__info {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;

		.l-row--variant-a & {
			background: $colour-accent;
		}

		.l-row--variant-b & {
			background: $colour-primary;
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c & {
			background: $colour-accent-dark;
			border-color: $colour-plain;
			color: $colour-plain;
		}

		.l-row--variant-d & {
			background: $colour-secondary;
			border-color: $colour-plain;
			color: $colour-plain;
		}
	}

	&__accordion {
		border-color: $colour-accent-secondary;

		.l-row--variant-b & {
			border-color: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-plain;
		}

		.accordion__item {
			background: transparent;
			border-radius: 0;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		.accordion__item__heading {

			&:before {

				.l-row--variant-b &,
				.l-row--variant-c &,
				.l-row--variant-d & {
					color: $colour-plain;
				}
			}

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}

		.accordion__item__content {

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {

			.accordion__item__toggle:checked + .accordion__item__heading,
			.accordion__item__toggle:checked[disabled] + .accordion__item__heading:hover {
				color: $colour-plain;

				&:before {
					color: $colour-plain;
				}
			}
		}
	}

	&--standard {

		.coverage__col {
			border-color: $colour-accent-secondary;

			.l-row--variant-b & {
				border-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}

			@include rtl() {
				border-color: $colour-accent-secondary;

				.l-row--variant-b & {
					border-color: $colour-primary-dark;
				}

				.l-row--variant-c &,
				.l-row--variant-d & {
					border-color: $colour-plain;
				}
			}
		}

		.coverage__breakdown {

			&--upsell {
				color: $colour-accent-secondary;

				.icon.icon--tick {
					color: $colour-accent-secondary;
				}
			}
		}

		&.coverage--active {

			.coverage__breakdown {

				&--upsell {
					color: $colour-accent-dark;

					.l-row--variant-b &,
					.l-row--variant-c &,
					.l-row--variant-d & {
						color: $colour-plain;
					}

					.icon.icon--tick {
						color: $colour-success;

						.l-row--variant-b &,
						.l-row--variant-c &,
						.l-row--variant-d & {
							color: $colour-plain;
						}
					}
				}
			}
		}
	}

	&--active {

		.coverage__overview,
		.coverage__info {
			border-color: $colour-primary;
			box-shadow: 0 0 0 1px $colour-primary;

			.l-row--variant-b & {
				border-color: $colour-plain;
				box-shadow: 0 0 0 1px $colour-plain;
			}
		}

		.coverage__header {
			background: $colour-primary;
			color: $colour-plain;

			.l-row--variant-b & {
				background: $colour-primary-dark;
			}
		}

		.coverage__toggle {
			border-color: $colour-plain;
			color: $colour-plain;
		}

		.coverage__heading {
			font-family: $font-family-brand;
			font-weight: $font-weight-brand;

			@include rtl() {
				font-family: $font-family-brand-arabic;
				font-weight: $font-weight-brand-arabic;
			}
		}

		.coverage__footer {

			.option-list__control {
				@extend .cta--secondary;
			}

			&--standard {

				.option-list__control,
				.option-list input:checked + .option-list__control--radio {
					background: transparent;
				}
			}
		}
	}
}
