.standard-form {

	&--card {
		@extend .card;
	}

	&__fieldset {

		&--stacked {
			border-bottom-color: $colour-accent-secondary;
		}

		&__heading {
			@extend %h3;
		}
	}

	&__row {

		&--separated {
			border-top-color: $colour-accent-secondary;

			.l-row--variant-b & {
				border-top-color: $colour-primary-dark;
			}

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-top-color: $colour-plain;
			}
		}
	}

	.error {
		color: $colour-error;
	}

	&__label {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}

	&__label-note {
		color: $colour-accent-dark;
	}

	&__input,
	&__textarea {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;
		color: $colour-accent-dark;
		font-family: $font-family-base;
		font-weight: $font-weight-base;

		@include rtl() {
			font-family: $font-family-base-arabic;
			font-weight: $font-weight-base-arabic;
		}

		&[disabled] {
			background: transparent;
			border: 0;
		}

		&--error {
			border-color: $colour-error;
		}

		&--valid {

			+ .icon.icon--tick-circled {
				color: $colour-success;
			}
		}
	}

	&__select {
		background: $colour-plain;
		border-color: $colour-accent-secondary;
		border-radius: $border-radius;
		color: $colour-accent-dark;

		&--error {
			border-color: $colour-error;
		}

		&--valid {

			+ .icon.icon--tick-circled {
				color: $colour-success;
			}
		}

		&:before {
			background: $colour-plain;
			color: $colour-primary;
		}

		select {
			color: $colour-accent-dark;
			font-family: $font-family-base;
			font-weight: $font-weight-base;

			@include rtl() {
				font-family: $font-family-base-arabic;
				font-weight: $font-weight-base-arabic;
			}

			&::-ms-value {
				background: transparent;
				color: $colour-accent-dark;
			}
		}
	}

	&__file-upload {

		&__file-name {
			background: $colour-plain;
			border-color: $colour-accent-secondary;
			border-radius: $border-radius;
			color: $colour-accent-dark;
			font-size: $font-size-base;
		}
	}

	&__submit {
		@extend .cta;
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}

		&--secondary {
			@extend .cta--secondary;
		}

		&--tertiary {
			@extend .cta--tertiary;
		}
	}

	+ .standard-form__actions {
		border-top-color: $colour-accent-secondary;

		.l-row--variant-b & {
			border-top-color: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			border-top-color: $colour-plain;
		}
	}
}
